import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./blog.css";
import blog1 from "./../assets/chardham-tour2.jpg";
import blog2 from "./../assets/seo-blogcard.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faWhatsapp,
  faTwitter,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const storedCategory = location.state?.selectedCategory || "All";
  const [selectedCategory, setSelectedCategory] = useState(storedCategory);
  const [currentPage, setCurrentPage] = useState(1);
  const [sharedBlog, setSharedBlog] = useState(null);

  const data = [
    {
      id: 1,
      name: "Exploring Kedarnath and Badrinath",
      title: "Pilgrimage of the Soul: Exploring Kedarnath and Badrinath",
      image: blog1,
      date: "March 8, 2024",
      previewText: "This is a preview of the first sample blog.",
      category: "Travel",
    },
    {
      id: 2,
      name: "12 Essential SEO Tools You’ll Absolutely Love to Boost Your Success",
      title: "12 Essential SEO Tools You’ll Absolutely Love to Boost Your Success",
      image: blog2, // Replace with actual image path
      date: "March 15, 2024",
      previewText: "This is a preview of the second sample blog.",
      category: "Digital Marketing",
    },
    // {
    //   id: 3,
    //   name: "AI in Technology",
    //   title: "The Future of AI in Technology",
    //   image: blog1, // Replace with actual image path
    //   date: "March 15, 2024",
    //   previewText: "This is a preview of the third sample blog.",
    //   category: "Technology",
    // },
    // {
    //   id: 4,
    //   name: "Sample Blog 4",
    //   title: "Sample Blog Title 4",
    //   image: blog1, // Replace with actual image path
    //   date: "March 15, 2024",
    //   previewText: "This is a preview of the fourth sample blog.",
    //   category: "Technology",
    // },
    // {
    //   id: 5,
    //   name: "Sample Blog 5",
    //   title: "Sample Blog Title 5",
    //   image: blog1, // Replace with actual image path
    //   date: "March 15, 2024",
    //   previewText: "This is a preview of the fifth sample blog.",
    //   category: "Travel",
    // },
    // {
    //   id: 6,
    //   name: "Sample Blog 6",
    //   title: "Sample Blog Title 6",
    //   image: blog1, // Replace with actual image path
    //   date: "March 15, 2024",
    //   previewText: "This is a preview of the sixth sample blog.",
    //   category: "Technology",
    // },
    // {
    //   id: 7,
    //   name: "Sample Blog 7",
    //   title: "Sample Blog Title 7",
    //   image: blog1, // Replace with actual image path
    //   date: "March 15, 2024",
    //   previewText: "This is a preview of the seventh sample blog.",
    //   category: "Digital Marketing",
    // },
  ];

  const categories = [
    "All",
    "Travel",
    "Digital Marketing",
    "Technology",
    "Sci-Fi",
  ];
  const itemsPerPage = 6;

  // Filter data based on selected category
  const filteredData =
    selectedCategory === "All"
      ? data
      : data.filter((blog) => blog.category === selectedCategory);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Reset to the first page when the category changes
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory]);

  const handleClick = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const navigateToTourDetails = (title, category) => {
    const normalizedCategory = category.toLowerCase().replace(/\s+/g, "-");
    const blog = data.find(
      (blog) => blog.title === title && blog.category === category
    );
    if (blog) {
      const blogNameSlug = title.split(" ").join("-").toLowerCase();
      navigate(`/my-blog/${normalizedCategory}/${blogNameSlug}`);
    }
  };

  const shareBlog = (platform, blog) => {
    setSharedBlog(blog);

    const blogNameSlug = blog.title.split(" ").join("-").toLowerCase();
    const blogUrl = `${
      window.location.origin
    }/my-blog/${blog.category.toLowerCase()}/${blogNameSlug}`;
    const encodedUrl = encodeURIComponent(blogUrl);
    const encodedTitle = encodeURIComponent(blog.title);
    const encodedImageUrl = encodeURIComponent(blog.image);

    let shareUrl = "";
    switch (platform) {
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
        break;
      case "whatsapp":
        shareUrl = `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`;
        break;
      case "pinterest":
        shareUrl = `https://pinterest.com/pin/create/button/?url=${encodedUrl}&media=${encodedImageUrl}&description=${encodedTitle}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <Helmet>
        <title>Blogs | Snehashish Patra</title>
        {sharedBlog && (
          <>
            <meta property="og:title" content={sharedBlog.title} />
            <meta property="og:description" content={sharedBlog.previewText} />
            <meta property="og:image" content={sharedBlog.image} />
            <meta
              property="og:url"
              content={`${window.location.origin}/blog/${sharedBlog.title
                .split(" ")
                .join("-")
                .toLowerCase()}`}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={sharedBlog.title} />
            <meta name="twitter:description" content={sharedBlog.previewText} />
            <meta name="twitter:image" content={sharedBlog.image} />
          </>
        )}
      </Helmet>
      <section>
        <h1>Read Blogs</h1>
        <div className="container">
          <p style={{ fontSize: "18px" }}>
            Hello! I'm Snehashish Patra, a tech enthusiast and marketing
            aficionado. With a passion for the latest trends in technology and
            marketing, I created this blog to share insights, tools, and
            innovations that shape our world. Here, you'll find in-depth
            articles, practical tips, and thought-provoking discussions on a
            variety of topics.
          </p>
          <br />
          <p style={{ fontSize: "18px" }}>
            <h3>Why Follow My Blog?</h3>
            <ol>
              <li>
                Up-to-date Information: I stay on top of the latest trends and
                technologies to bring you the most current and relevant
                information.
              </li>
              <li>
                In-depth Analysis: Each post delves deep into the subject
                matter, providing comprehensive insights and actionable
                takeaways.
              </li>
              <li>
                Practical Tips: Get practical advice and tips that you can apply
                directly to your projects and business strategies.
              </li>
              <li>
                Expert Opinions: Benefit from interviews and guest posts by
                industry experts who share their knowledge and experience.
              </li>
            </ol>
          </p>
        </div>
        <br />
        <h2>Latest Trends</h2>
        <br />
        <div className="container category-buttons">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`btn ${
                selectedCategory === category ? "btn-primary" : ""
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="container portfolio__container">
          {paginatedData.length > 0 ? (
            paginatedData.map(
              ({ id, image, title, name, previewText, category }) => (
                <article key={id} className="portfolio__item">
                  <div className="portfolio__item-image">
                    <img src={image} alt={title} />
                  </div>
                  <h3>{name}</h3>
                  <div className="portfolio__item-cta">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigateToTourDetails(title, category)}
                    >
                      Read More
                    </button>
                    <div className="share-button-container">
                      <button className="btn btn-primary">
                        <FontAwesomeIcon icon={faShareAlt} /> Share
                      </button>
                      <div className="share-options">
                        <button
                          onClick={() =>
                            shareBlog("linkedin", {
                              title,
                              image,
                              previewText,
                              category,
                            })
                          }
                        >
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            className="fa-linkedin"
                          />
                        </button>
                        <button
                          onClick={() =>
                            shareBlog("whatsapp", {
                              title,
                              image,
                              previewText,
                              category,
                            })
                          }
                        >
                          <FontAwesomeIcon
                            icon={faWhatsapp}
                            className="fa-whatsapp"
                          />
                        </button>
                        <button
                          onClick={() =>
                            shareBlog("twitter", {
                              title,
                              image,
                              previewText,
                              category,
                            })
                          }
                        >
                          <FontAwesomeIcon
                            icon={faTwitter}
                            className="fa-twitter"
                          />
                        </button>
                        <button
                          onClick={() =>
                            shareBlog("pinterest", {
                              title,
                              image,
                              previewText,
                              category,
                            })
                          }
                        >
                          <FontAwesomeIcon
                            icon={faPinterest}
                            className="fa-pinterest"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </article>
              )
            )
          ) : (
            <h3 className="centered-title">
              Stay tuned! Exciting new blog posts are on the way.
            </h3>
          )}
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handleClick(index + 1)}
              className={`btn ${
                currentPage === index + 1 ? "btn-primary" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </section>
    </>
  );
};

export default Blog;
