import React from 'react'
import Experience from '../components/experience/Experience'
import Nav from '../components/nav/Nav'
import { Helmet } from 'react-helmet-async'

const MyExperience = () => {
  return (
    <div>
      <Helmet>
        <title>My Experience | Snehashish Patra</title>
      </Helmet>
        <Experience/>
        <Nav/>
    </div>
  )
}

export default MyExperience