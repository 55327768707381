import { Helmet } from 'react-helmet-async'
import Nav from '../components/nav/Nav'
import About from './../components/about/About'

const AboutMe = () => {
  return (
    <div>
      <Helmet>
        <title>About Me</title>
      </Helmet>
       <About />
       <Nav/>
    </div>
   
  )
}

export default AboutMe