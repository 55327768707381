import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import ReactGA from 'react-ga';
import './index.css';

// Initialize ReactGA
ReactGA.initialize('G-Z6ZYW4WQTR'); // Replace with your tracking ID
ReactGA.pageview(window.location.pathname + window.location.search);

// Get the root element
const rootElement = document.getElementById('root');

// Create a root
const root = createRoot(rootElement);

// Render the app
root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>
);
