import React from 'react'
import PortfolioCard from '../components/portfolio/Portfolio'
import Nav from '../components/nav/Nav'
import { Helmet } from 'react-helmet-async'
const Portfolio = () => {
  return (
    <>
    <Helmet>
      <title>Certifications | Snehashish Patra</title>
    </Helmet>
    <section>
        <PortfolioCard/>
        <Nav/>
    </section>
    </>
  )
}

export default Portfolio