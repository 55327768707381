import React from "react";
import Nav from "../components/nav/Nav";
import Hero from "../components/header/Header";
import About from "../components/about/About";
import Experience from "../components/experience/Experience";
import Img from "./../assets/ash2.jpg";
import { Helmet } from "react-helmet-async";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Designing Digital Dreams | Snehashish Patra</title>

        <script type="application/ld+json">
          {`
        {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Designing Digital Dreams | Snehashish Patra",
  "description": "A portfolio page of Snehashish Patra, showcasing his work and experience in digital design.",
  "url": "https://www.snehashishpatra.me",
  "author": {
    "@type": "Person",
    "name": "Snehashish Patra",
    "url": "https://www.snehashishpatra.me",
    "sameAs": [
      "https://www.linkedin.com/in/snehashish-patra/",
      "https://x.com/SnehashishPatr4"
    ]
  },
  "publisher": {
    "@type": "Organization",
    "name": "Your Organization",
    "logo": {
      "@type": "ImageObject",
      "url": "${Img}"
    }
  },
  "mainEntity": {
    "@type": "Person",
    "name": "Snehashish Patra",
    "jobTitle": "Digital Designer",
    "description": "Snehashish Patra is a digital designer specializing in creating stunning and functional digital experiences.",
    "image": "${Img}",
    "worksFor": {
      "@type": "Organization",
      "name": "Personal Portfolio",
      "url": "https://www.snehashishpatra.me"
    },
    "sameAs": [
      "https://www.linkedin.com/in/snehashish-patra/",
      "https://x.com/SnehashishPatr4"
    ]
  }
}`}
        </script>
      </Helmet>

      <div className="relative h-full w-full bg-slate-950">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[radial-gradient(circle_500px_at_50%_200px,#3e3e3e,transparent)]"></div>
        <Hero />
        <About />
        <Experience />
        <Nav />
      </div>
    </>
  );
};

export default Home;
