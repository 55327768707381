import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Portfolio from "../pages/Portfolio";
import Home from "../pages/Home";
import Contact from "../components/contact/Contact";
import About from "../pages/About";
import Offer from "../pages/Offer";
import MyExperience from "../pages/MyExperience";
import Blog from "../pages/Blog";
import KedarnathBadrinath1 from "../pages/blogs/KedarnathBlog";
import SeoTools from "../pages/blogs/SeoTools";
// import Holograd from "../pages/registration/HologradReg";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-me" element={<Contact />} />
        <Route path="/about-me" element={<About />} />
        <Route path="/my-certifications" element={<Portfolio />} />
        <Route path="/what-i-offer" element={<Offer />} />
        <Route path="/my-experience" element={<MyExperience />} />
        <Route path="/my-blog" element={<Blog />} />
        {/*---------------Blogs---------------------*/}
        <Route path="/my-blog/travel/pilgrimage-of-the-soul:-exploring-kedarnath-and-badrinath" element={<KedarnathBadrinath1 />} />
        <Route path="/my-blog/digital-marketing/12-essential-seo-tools-you’ll-absolutely-love-to-boost-your-success" element={<SeoTools />} />

        {/*---------------ADS---------------------*/}
        {/* <Route path="/registrations/special-discount" element={<Holograd />} /> */}
      </Routes>
    </Router>
  );
};

export default AppRouter;
