import Router from '../../router/Router'
import Footer from '../footer/Footer'
// import Header from '../header/Header';

const Layout = () => {
  return <>
    {/* <Header/> */}
    <Router/>
    <Footer/>
    </>
    
};

export default Layout