import React, { useState, useEffect } from 'react'
import './nav.css'
import { SiHomeadvisor } from 'react-icons/si'
import { RiServiceLine } from 'react-icons/ri'
import { TbFileCertificate } from 'react-icons/tb'
import { GoMailRead } from 'react-icons/go'
import { FaBloggerB } from 'react-icons/fa'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('/')

  useEffect(() => {
    // Set activeNav based on current window location path
    setActiveNav(window.location.pathname)
  }, [])

  return (
    <nav>
      <a href="/" onClick={() => setActiveNav('/')} className={activeNav === '/' ? 'active' : ''} alt='Home'>
        <SiHomeadvisor />
      </a>
      <a href="/what-i-offer" onClick={() => setActiveNav('/what-i-offer')} className={activeNav === '/what-i-offer' ? 'active' : ''} alt='What I Offer'>
        <RiServiceLine />
      </a>
      <a href="/my-certifications" onClick={() => setActiveNav('/my-certifications')} className={activeNav === '/my-certifications' ? 'active' : ''} alt='Certificate'>
        <TbFileCertificate />
      </a>
      <a href="/contact-me" onClick={() => setActiveNav('/contact-me')} className={activeNav === '/contact-me' ? 'active' : ''} alt='Contact Me'>
        <GoMailRead />
      </a>
      <a href="/my-blog" onClick={() => setActiveNav('/my-blog')} className={activeNav === '/my-blog' ? 'active' : ''} alt='Blog'>
        <FaBloggerB />
      </a>
    </nav>
  )
}

export default Nav
