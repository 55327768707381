import React from "react";
import "./about.css";
import ME from "../../assets/me007.png";
// import ME from "../../assets/me-about.png";
import { FaAward } from "react-icons/fa";
import { TbCertificate } from "react-icons/tb";
import { IoLibrarySharp } from "react-icons/io5";

const About = () => {
  return (
    <>
      <section id="about" >
        <h5>Get To Know</h5>
        <h2>About Me</h2>

        <div className="container about__container">
          <div className="about__me">
            <div className="about__me-image">
              <img src={ME} alt="About ME" />
            </div>
          </div>
          <div className="about__content">
            <div className="about__cards">
              <article className="about__card">
                <FaAward className="about__icon" />
                <h5>Experience</h5>
                <small>3+ Years And Still Learning</small>
              </article>

              <article className="about__card">
                <TbCertificate className="about__icon" />
                <h5>Certification</h5>
                <small>50+ Certification on Google Cloud</small>
              </article>

              <article className="about__card">
                <IoLibrarySharp className="about__icon" />
                <h5>Projects</h5>
                <small>10+ Completed Projects</small>
              </article>
            </div>
            <br />
            <p>
              Snehashish here. <br />
            A dedicated professional with a background in B.Sc IT Cloud Computing from Parul University. Passionate about using my IT skills to create positive social impacts, I currently lead Tech Services & SEO at Skillwise Solutions.
<br />
At Skillwise Solutions, I excel in keyword research, crafting SEO-friendly content, and implementing effective SEO strategies across various platforms. My journey includes valuable experiences as an SEO Intern, where I developed skills in data analysis, content creation, link building, technical SEO, and performance monitoring.
            </p>

            <a href="/contact-me" className="btn btn-primary">
              Let's Talk
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
