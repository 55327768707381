import { Analytics } from '@vercel/analytics/react';
import "./index.css";
import { SpeedInsights } from "@vercel/speed-insights/react"
import Layout from "./components/Layout/Layout";
function App() {
  return <>
  <Analytics />
  <SpeedInsights />
  <Layout/>
  </>
}

export default App;