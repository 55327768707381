import React from 'react'
import Services from '../components/services/Services'
import Nav from '../components/nav/Nav'
import { Helmet } from 'react-helmet-async'

const Offer = () => {
  return (
    <div>
      <Helmet>
        <title>What I Offer | Snehashish Patra</title>
      </Helmet>
        <Services/>
        <Nav/>
    </div>
  )
}

export default Offer