import React from 'react'
import './footer.css'
import {FaMedium} from 'react-icons/fa'
import {IoLogoLinkedin} from 'react-icons/io5'
import {AiFillInstagram} from 'react-icons/ai'
import Nav from '../nav/Nav'

const Footer = () => {
  return (
    <>
    <footer>
      <a className='footer__logo' rel="noreferrer">Snehashish</a>
      <ul className='permalinks'>
        <li><a href="/">Home</a></li>
        <li><a href="/about-me">About</a></li>
        <li><a href="/my-experience">Experience</a></li>
        {/* <li><a href="/my-certifications">Portfolio</a></li> */}
        <li><a href="/my-certifications">Certifications</a></li>
        {/* <li><a href="/testimonials">Testimonials</a></li> */}
        <li><a href="/contact-me">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://snehashishpatra.medium.com/" alt='Medium'><FaMedium/></a>
        <a href="https://www.linkedin.com/in/snehashish-patra/" alt='LinkedIn'><IoLogoLinkedin/></a>
        <a href="https://www.instagram.com/ashcien_06/" alt='Instagram'><AiFillInstagram/></a>
      </div>

      <div className="footer__copyright">
        <small> &copy; Snehashish. All rights reserved.</small>
      </div>
    </footer>
    <Nav/>
    </>
  )
}

export default Footer